.what-is {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 84px;
    position: relative;

    @media (max-width: $lg) {
        flex-direction: column;
        margin-top: 84px;
        gap: 42px; }

    > svg {
        position: absolute;
        top: -130px;
        right: -80px;

        @media (max-width: $lg) {
            display: none; } }

    h2 {
        margin-bottom: 24px;

        @media (max-width: $lg) {
            margin-bottom: 16px; }

        span {
            color: transparent;
            -webkit-background-clip: text !important;
            background-clip: text !important;
            background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%); } }

    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        color: $gray;

        @media (max-width: $lg) {
            font-size: 16px;
            line-height: 22px; } }

    img {
        @media (max-width: $lg) {
            width: 100%;
            height: auto; } }

    &.active {
        img {
            animation: fade-zoom 1s ease-in; } } }
