.checkbox {
    position: relative;

    label {
        a {
            @media (max-width: $lg) {
                display: contents; } } } }

.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 25px;
    left: 2px; }

.custom-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    margin-top: 20px;
    color: white;
    font-size: 12px;
    font-weight: 400;

    p {
        display: inline; }

    a {
        color: #3055D8; } }

.custom-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 10px;
    margin-top: -5px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox:checked+label::before {
    border-color: #3055D8;
    background-color: #3055D8;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox:not(:disabled):active+label::before {
    background-color: #0D9866;
    border-color: #0D9866; }

.custom-checkbox:disabled+label::before {
    background-color: #e9ecef; }
