.breadcrumbs {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    position: relative;
    z-index: 2;
    font-size: 16px;
    line-height: 19px;

    li {
        display: flex;
        align-items: center;
        gap: 4px;

        a {
            color: white; }

        .disable {
            color: rgba(255,255,255,.5);
            pointer-events: none;
            @media (max-width: $lg) {
                display: none; } } }

    &__separator-mob {
        line-height: 80%;
        height: 19px;
        width: 19px;
        max-height: 19px;
        max-width: 19px;
        display: none;
        color: white;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);

        @media (max-width: $lg) {
            display: flex; } }

    &__separator {
        line-height: 80%;
        height: 19px;
        width: 19px;
        max-height: 19px;
        max-width: 19px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $lg) {
            display: none; } } }
