.promo {
    background: #EBEEFB;
    border-radius: 32px;
    padding: 42px 32px;
    display: flex;
    flex-direction: column;
    gap: 42px;
    margin-top: 84px;

    @media (max-width: $lg) {
        margin-top: 32px;
        padding: 0 12px;
        padding-top: 32px;
        padding-bottom: 12px;
        border-radius: 20px;
        gap: 32px; }

    h2 {
        font-size: 30px;
        font-weight: 800;
        line-height: 33px;
        text-align: center;
        color: $black;

        @media (max-width: $lg) {
            font-size: 24px;
            line-height: 112%; } }

    &__block {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        @media (max-width: $lg) {
            gap: 10px; } }

    &__item {
        padding: 16px 24px;
        background: white;
        border-radius: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;

        @media (max-width: $lg) {
            gap: 12px;
            padding: 10px 16px 10px 16px; }

        &:nth-child(1), &:nth-child(4), &:nth-child(5) {
            max-width: 48%;

            @media (max-width: $lg) {
                max-width: 100%; } }

        &:nth-child(2), &:nth-child(3), &:nth-child(6) {
            max-width: 42%;

            @media (max-width: $lg) {
                max-width: 100%; } }

        p {
            color: $black;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;

            @media (max-width: $lg) {
                font-size: 16px;
                line-height: 140%; } } } }
