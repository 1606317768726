// Starter template
@import './page/page';
@import "./hamburger/hamburger";
@import "./module/module";
@import "./mainmenu/mainmenu";

// Components
@import "./footer/footer";
@import "./header/header";
@import "./overlay/overlay";
@import "./checkbox/checkbox";
@import "./animanitons/animanitons";

//Modals
@import "./modals/request/request";
@import "./modals/thank/thank";
@import "./modals/lead/lead";

// Sections
@import "./sections/promo/promo";
@import "./sections/conversion/conversion";
@import "./sections/what-is/what-is";
@import "./sections/dies/dies";
@import "./sections/blocks-reverse/blocks-reverse";
@import "./sections/benefits/benefits";
@import "./sections/leadform/leadform";
@import "./sections/breadcrumbs/breadcrumbs";

@import "./sections/blog/blog-home/blog-home";
@import "./sections/blog/blog-article/blog-article";
