.leadform {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    gap: 48px;
    position: relative;

    @media (max-width: $lg) {
        margin-top: 84px;
        gap: 32px;
        flex-direction: column; }

    > svg {
        position: absolute;
        top: -120px;
        right: 20px;
        z-index: 1;

        @media (max-width: $lg) {
            display: none; } }

    &-social {
        padding: 32px 64px 48px 32px;
        border-radius: 30px;
        background: rgb(255, 255, 255);
        height: fit-content;
        position: relative;
        z-index: 2;
        width: 100%;

        @media (max-width: $lg) {
            border-radius: 20px;
            padding: 20px;
            width: initial; }

        h2 {
            margin-bottom: 32px; }

        &__block {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            @media (max-width: $lg) {
                gap: 12px; }

            a {
                height: 52px;
                max-width: 265px;
                width: 100%;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                box-shadow: 0px 10px 20px 0px rgb(213, 217, 239);
                font-size: 16px;
                font-weight: 400;
                color: white;
                line-height: 114%;

                @media (max-width: $lg) {
                    max-width: 100%;
                    height: 48px;
                    font-size: 14px; }

                &:first-child {
                    background: linear-gradient(180.00deg, rgb(42, 171, 238),rgb(34, 158, 217) 10000%); }

                &:nth-child(2) {
                    background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%); }

                &:nth-child(3) {
                    background: linear-gradient(0.00deg, rgb(31, 175, 56) -9900%,rgb(96, 214, 105) 100%); }
                &:nth-child(4) {
                    background: rgb(48, 85, 216); } } } }

    &-form {
        border-radius: 30px;
        background: linear-gradient(140.62deg, rgb(247, 235, 251) -11.107%,rgb(235, 238, 251) 110.124%);
        padding: 32px 64px 32px 32px;
        max-width: calc( 646px - 32px - 64px );
        width: 100%;
        position: relative;
        z-index: 2;

        @media (max-width: $lg) {
            max-width: calc( 100% - 40px );
            padding: 20px;
            border-radius: 20px; }

        .thank {
            position: absolute;
            background: linear-gradient(95.15deg, #F7EBFB -7.64%, #EBEEFB 104.27%);
            top: 0;
            left: 0;
            max-width: calc( 646px - 64px );
            width: 100%;
            height: 100%;
            display: none;
            flex-direction: column;
            gap: 48px;
            padding: 0 32px;
            border-radius: 30px;

            @media (max-width: $lg) {
                justify-content: center;
                padding: 20px;
                max-width: calc( 100% - 40px );
                gap: 32px;
                border-radius: 20px;
                height: calc( 100% - 40px ); }

            &.active {
                display: flex; }

            h2 {
                padding-top: 76px;
                text-align: center;
                font-size: 36px;
                line-height: 40px;
                font-weight: 800;
                color: transparent;
                -webkit-background-clip: text !important;
                background-clip: text !important;
                background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%);
                margin-bottom: 0;

                @media (max-width: $lg) {
                    padding-top: 0;
                    font-size: 28px;
                    line-height: 30px;
                    max-width: 320px;
                    margin: 0 auto; } }

            picture {
                max-width: fit-content;
                margin: 0 auto; }

            .button {
                border-radius: 12px;
                box-shadow: 0px 10px 20px 0px rgb(213, 217, 239);
                background: linear-gradient(220.7deg, var(--myColor1) -22%, var(--myColor2) 107.02%);
                transition: --myColor1 0.8s, --myColor2 0.8s;
                color: white;
                max-width: 300px;
                height: 58px;
                margin: 0 auto;
                font-size: 16px;
                font-weight: 400;
                line-height: 114%;

                &:hover {
                    --myColor1: #2244BB;
                    --myColor2: #2244BB;
                    opacity: 1; }

                @media (max-width: $lg) {
                    max-width: 288px;
                    height: 50px;
                    font-size: 14px; } } }

        h2 {
            color: transparent;
            -webkit-background-clip: text !important;
            background-clip: text !important;
            background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%);
            margin-bottom: 32px;

            @media (max-width: $lg) {
                margin-bottom: 24px; } }

        form {
            flex-direction: column;
            display: flex;
            max-width: 440px;

            label {
                margin-bottom: 12px;
                font-size: 14px;
                font-weight: 400;
                line-height: 114%;
                color: $black;

                @media (max-width: $lg) {
                    margin-bottom: 10px; } }

            input {
                border-radius: 12px;
                background: rgb(255, 255, 255);
                max-width: calc( 100% - 36px );
                width: 100%;
                height: 59px;
                outline: none;
                border: none;
                padding: 0 18px;
                margin-bottom: 20px;
                font-size: 18px;
                font-weight: 400;
                line-height: 150%;
                color: $black;
                box-shadow: 0px 16px 19.3px 0px #112B851C;

                @media (max-width: $lg) {
                    height: 50px;
                    border-radius: 10px;
                    padding: 0 16px; }

                &::placeholder {
                    color: $gray; }

                &:last-child {
                    margin-bottom: 0; } }

            .checkbox {
                label {
                    font-size: 13px; } }

            .button {
                border-radius: 12px;
                box-shadow: 0px 10px 20px 0px rgb(213, 217, 239);
                background: linear-gradient(220.53deg, var(--myColor1) -22%, var(--myColor2) 107.02%);
                transition: --myColor1 0.8s, --myColor2 0.8s;
                height: 58px;
                max-width: 440px;
                margin-top: 8px;

                &:hover {
                    --myColor1: #2244BB;
                    --myColor2: #2244BB;
                    opacity: 1; }

                @media (max-width: $lg) {
                    height: 50px;
                    font-size: 14px;
                    margin-top: 12px; } } } } }
