.benefits {
    margin-top: 150px;
    position: relative;

    @media (max-width: $lg) {
        margin-top: 84px; }

    > svg {
        position: absolute;
        top: 140px;
        right: 200px;
        z-index: 1;

        @media (max-width: $lg) {
            display: none; } }

    h2 {
        margin-bottom: 48px;

        @media (max-width: $lg) {
            margin-bottom: 32px; } }

    &__block {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        @media (max-width: $lg) {
            gap: 16px; } }

    &__item {
        padding: 32px;
        border-radius: 24px;
        background: white;
        flex: 1;
        flex-basis: 366px;
        position: relative;
        z-index: 2;

        @media (max-width: $lg) {
            padding: 20px;
            border-radius: 20px; }

        svg {
            margin-bottom: 32px;

            @media (max-width: $lg) {
                margin-bottom: 20px;
                width: 42px;
                height: 42px; } }

        h3 {
            font-size: 26px;
            font-weight: 600;
            line-height: 112%;
            color: $black;
            margin-bottom: 20px;

            @media (max-width: $lg) {
                margin-bottom: 16px;
                font-size: 22px; } }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            color: $gray;

            @media (max-width: $lg) {
                font-size: 16px;
                line-height: 140%; } } } }
