::root {
    --vh: 1vh; }

.header {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $lg) {
        margin-top: 0;
        padding-top: 16px;
        padding-bottom: 16px; }

    &__logo {
        @media (max-width: $lg) {
            display: flex; }

        svg {
            @media (max-width: $lg) {
                height: 34px;
                width: 142px; } } }

    &__hamburger {
        display: none;

        @media (max-width: $lg) {
            display: block; } }

    &__block {
        display: flex;
        align-items: center;
        gap: 32px;
        width: 100%;
        justify-content: flex-end;

        @media (max-width: $lg) {
            gap: 10px; }

        .header__menu {
            @media (max-width: $lg) {
                display: none; } } }

    &__socials {
        display: flex;
        align-items: center;
        gap: 8px;

        @media (max-width: $lg) {
            gap: 10px; }

        a {
            height: 36px;

            &:first-child {
                @media (max-width: $lg) {
                    display: none; } }

            &:nth-child(2), &:nth-child(3) {
                svg {
                    &:first-child {
                        @media (max-width: $lg) {
                            display: none; } }

                    &:last-child {
                        display: none;

                        @media (max-width: $lg) {
                            display: block; } } } }

            @media (max-width: $lg) {
                width: 32px;
                height: 32px; }

            svg {
                @media (max-width: $lg) {
                    width: 32px;
                    height: 32px; } } } }

    &__button {
        background: linear-gradient(220.7deg, var(--myColor1) -22%, var(--myColor2) 107.02%);
        transition: --myColor1 0.8s, --myColor2 0.8s;
        max-width: 176px;
        width: 100%;
        height: 48px;
        font-size: 16px;
        line-height: 18px;

        @media (max-width: $lg) {
            border-radius: 100%;
            width: 32px;
            max-width: 32px;
            height: 32px; }

        &:hover {
            --myColor1: #2244BB;
            --myColor2: #2244BB;
            opacity: 1; }

        p {
            color: white; }

        svg {
            display: none;

            @media (max-width: $lg) {
                display: block; } }

        p {
            @media (max-width: $lg) {
                display: none; } } }

    &-mob {
        display: none;
        position: absolute;
        z-index: 11;
        left: 0;
        top: 0;
        background: $black;
        width: 100%;
        max-width: 100vw;
        height: calc(var(--vh, 1vh) * 100);
        flex-direction: column;
        justify-content: space-between;
        top: calc( -100vh - 48px );
        transition: all 0.8s ease;

        &.is-active {
            top: 0; }

        @media (max-width: $lg) {
            display: flex; }

        &__row {
            display: flex;
            justify-content: space-between; }

        &__socials {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 32px; }

        &__button {
            max-width: 100%;
            height: 54px;
            background: white;

            p {
                color: transparent;
                -webkit-background-clip: text !important;
                background-clip: text !important;
                background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%); } }

        &__bottom {
            padding: 0 16px;
            padding-bottom: 32px; }

        &-dop {
            padding: 0 16px;
            padding-top: 16px; } } }
