.blog-page-article {
    margin-bottom: 20px;

    &__promo {
        background: linear-gradient(270deg, #3BC4FF 0%, rgba(13, 152, 102, 0.40) 99.37%), #202020;
        margin-top: 96px;

        @media (max-width: $lg) {
            margin-top: 36px; }

        .container-main {
            position: relative;
            padding: 76px 10px;
            display: flex;
            justify-content: space-between;
            gap: 30px;

            @media (max-width: $lg) {
                padding: 30px 10px;
                gap: 10px;
                flex-direction: column; } }

        &-text {
            max-width: 868px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: $lg) {
                margin-bottom: 18px;
                gap: 24px; } }

        &-bottom {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 30px; }

        &-date {
            color: rgba(255, 255, 255, 0.90); }

        &-views {
            display: flex;
            align-items: center;
            gap: 4px;
            color: rgba(255, 255, 255, 0.90);

            svg {
                max-width: 19px;
                width: 100%;
                height: auto;

                path {
                    fill: rgba(255, 255, 255, 0.90); } } }

        &-img img {
            min-width: 400px;
            max-width: 400px;
            width: 100%;
            object-fit: cover;
            max-height: 400px;
            height: auto;

            @media (max-width: $lg) {
                min-width: auto;
                height: auto; } }

        &-block {
            margin-top: 60px;

            @media (max-width: $lg) {
                margin-top: 16px; }

            h1 {
                color: rgba(255, 255, 255, 0.90);
                text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.40);
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%;
                margin-bottom: 24px;

                @media (max-width: $lg) {
                    font-size: 24px;
                    margin-bottom: 12px; } }

            p {
                color: rgba(255, 255, 255, 0.90);
                text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.40);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0.2px;

                @media (max-width: $lg) {
                    font-size: 16px; } } } } }

.article__body {
    margin: 0 auto;
    max-width: 1340px;
    margin-top: 20px; }

.article__content {
    @media (max-width: $lg) {
        padding: 0 10px; }

    .quiz__qna {
        margin-top: 24px;
        margin-bottom: 24px;

        ul,p {
            font-size: 20px;

            @media (max-width: $lg) {
                font-size: 18px; } }

        ul {
            list-style: disc;
            display: flex;
            flex-direction: column;
            gap: 10px; } }

    .article-baner {
        padding: 50px;
        background: linear-gradient(270deg, #3BC4FF 0%, rgba(13, 152, 102, 0.4) 99.37%), #202020;
        border-radius: 42px;
        color: white;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            padding: 16px;
            border-radius: 20px;
            margin-bottom: 16px; }

        > h2 {
            font-size: 50px;
            font-weight: 700;
            line-height: 120%;
            color: white;
            margin: 0;
            margin-bottom: 48px;

            @media (max-width: $lg) {
                font-size: 24px;
                margin-bottom: 24px; } }

        &__block {
            display: flex;
            gap: 30px;
            justify-content: space-between;

            @media (max-width: $lg) {
                flex-direction: column-reverse;
                align-items: center; } }

        &__expret {
            border: 0.5px solid rgb(255, 255, 255);
            border-radius: 40px;
            background: rgba(255, 255, 255, 0.5);
            padding: 32px 24px 54px 24px;
            height: fit-content;
            max-width: 340px;
            width: 100%;
            margin-top: -40px;

            @media (max-width: $lg) {
                border-radius: 20px;
                padding: 24px;
                margin-top: 0; }

            h2 {
                color: rgb(133, 0, 125);
                margin: 0;
                margin-bottom: 30px;
                font-size: 34px;
                font-weight: 700;
                line-height: 110%;
                text-align: center;

                @media (max-width: $lg) {
                    font-size: 24px;
                    margin-bottom: 24px; } }

            img {
                max-width: 150px;
                height: 150px;
                border-radius: 100%;
                margin: 0 auto;

                @media (max-width: $lg) {
                    max-width: 120px;
                    height: 120px; } } }

        &__text {
            width: 100%;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 14px;
                margin: 0;
                margin-top: 48px;
                border-radius: 20px;
                background: rgb(37, 211, 102);
                height: 85px;
                max-width: 360px;
                width: 100%;
                font-size: 24px;
                font-weight: 500;
                line-height: 149.3%;
                color: white;

                @media (max-width: $lg) {
                    margin-top: 24px;
                    height: 60px;
                    font-size: 18px;
                    gap: 0; } }

            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin: 0;

                li {
                    max-width: 570px;
                    width: 100%;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 140%;
                    color: rgb(34, 34, 34);
                    padding-left: 20px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    border-radius: 16px;
                    box-shadow: 6px 8px 0px 0px rgba(0, 0, 0, 0.25);
                    background: rgba(255, 255, 255, 0.5);
                    margin: 0;

                    svg {
                        margin-right: 12px; }

                    b {
                        color: rgb(133, 0, 125); }

                    @media (max-width: $lg) {
                        font-size: 14px;
                        height: auto;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        padding-left: 10px;
                        padding-right: 10px;
                        line-height: 110%; } } } } }

    img {
        display: block;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 10px;
        max-width: 100%;

        @media (max-width: $lg) {
            max-width: 100%;
            width: 100%;
            object-fit: cover; } }

    > h2,h3,h4,h5,h6 {
        margin-bottom: 10px;
        max-width: 850px;
        width: 100%;
        margin: 0 auto; }

    h2 {
        font-size: 30px; }

    h3 {
        font-size: 26px; }

    h4 {
        font-size: 24px; }
    // List
    > ol,
    > ul {
        color: black;
        font-size: 22px;
        margin: 0 auto;
        list-style: disc;
        margin-bottom: 15px;
        padding-left: 20px;
        line-height: 1.4;
        max-width: 850px;
        width: 100%;

        @media (max-width: $lg) {
            font-size: 18px; } }

    > li {
        max-width: 850px;
        width: 100%;
        margin: 0 auto;
        &:not(:last-child) {
            margin-bottom: 5px; } }

    a {
        color: blue; }

    figcaption {
        margin: 0 auto;
        max-width: fit-content;
        font-size: 18px;

        @media (max-width: $lg) {
            font-size: 16px; } }

    > p {
        max-width: 850px;
        width: 100%;
        margin: 0 auto;
        color: black;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.4;

        @media (max-width: $lg) {
            font-size: 18px; }

        a {
            color: blue;
            margin-left: 3px; }

        &:not(:last-child) {
            margin-bottom: 15px; }

        blockquote {
            border-left: 3px solid blue;
            padding-left: 30px;
            margin: 30px 0 30px 25px;
            font-size: 1rem;
            font-style: italic; } } }
