.mainmenu {
    display: flex;

    @media (max-width: $lg) {
        margin-top: 50px; }

    &__list {
        display: flex;
        align-items: center;
        gap: 32px;

        @media (max-width: $lg) {
            flex-direction: column;
            gap: 29px;
            align-items: baseline; }

        a {
            white-space: nowrap;
            color: $black;
            font-size: 18px;
            line-height: 20px;
            color: transparent;
            -webkit-background-clip: text !important;
            background-clip: text !important;
            background: linear-gradient(220.7deg, var(--myColor3) -22%, var(--myColor4) 107.02%);
            transition: --myColor3 0.8s, --myColor4 0.8s;

            &:hover {
                --myColor3: #CD01BA;
                --myColor4: #015AFE; }

            @media (max-width: $lg) {
                color: white; } } } }
