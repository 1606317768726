#modal-lead .modal-dialog {
    max-width: 700px;
    width: 100%;

    @media (max-width: $sm) {
        max-width: calc( 100% - 70px ); } }

.lead {
    h3 {
        font-size: 36px;
        font-weight: 800;
        line-height: 112%;
        color: $black;
        max-width: 500px;
        display: flow;
        max-width: 545px;

        @media (max-width: $sm) {
            font-size: 22px;
            max-width: 310px; }

        span {
            color: transparent;
            -webkit-background-clip: text !important;
            background-clip: text !important;
            background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%); } }

    &__row {
        display: flex;
        justify-content: space-between;

        picture {
            @media (max-width: $sm) {
                display: none; } } }

    form {
        margin-top: 32px;
        flex-direction: column;
        display: flex;
        max-width: 350px;

        @media (max-width: $sm) {
            margin-top: 24px; }

        label {
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 114%;
            color: $black;

            @media (max-width: $lg) {
                margin-bottom: 10px; } }

        input {
            border-radius: 12px;
            background: rgb(255, 255, 255);
            max-width: calc( 100% - 36px );
            width: 100%;
            height: 59px;
            outline: none;
            border: none;
            padding: 0 18px;
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            color: $black;
            box-shadow: 0px 16px 19.3px 0px #112B851C;

            @media (max-width: $lg) {
                height: 50px;
                border-radius: 10px;
                padding: 0 16px; }

            &::placeholder {
                color: $gray; }

            &:last-child {
                margin-bottom: 0; } }

        .checkbox {
            label {
                font-size: 13px;
                display: flex; } }

        .button {
            border-radius: 12px;
            box-shadow: 0px 10px 20px 0px rgb(213, 217, 239);
            background: linear-gradient(220.53deg, var(--myColor1) -22%, var(--myColor2) 107.02%);
            transition: --myColor1 0.8s, --myColor2 0.8s;
            height: 58px;
            max-width: 440px;
            margin-top: 8px;

            &:hover {
                --myColor1: #2244BB;
                --myColor2: #2244BB;
                opacity: 1; }

            @media (max-width: $lg) {
                height: 50px;
                font-size: 14px;
                margin-top: 12px; } } } }
