#modal-thank .modal-dialog {
    max-width: 700px;
    width: 100%;

    @media (max-width: $sm) {
        max-width: calc( 100% - 70px ); }

    > p {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 114%;
        color: $gray;

        @media (max-width: $sm) {
            font-size: 12px; }

        a {
            display: block;
            color: #3055D8; } } }

.thank {
    h3 {
        text-align: center;
        margin: 0 auto;
        font-size: 36px;
        line-height: 40px;
        font-weight: 800;
        color: $black;
        margin-bottom: 32px;
        margin-top: 36px;

        @media (max-width: $sm) {
            margin-top: 30px;
            margin-bottom: 16px;
            font-size: 24px;
            font-weight: 800;
            line-height: 112%;
            max-width: 300px; } }

    picture {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;

        @media (max-width: $sm) {
            margin-bottom: 16px; }

        img {
            @media (max-width: $sm) {
                width: 140px;
                height: 140px; } } }

    h4 {
        text-align: center;
        font-weight: 600;
        font-size: 26px;
        line-height: 29px;
        margin-bottom: 24px;
        color: $black;

        @media (max-width: $sm) {
            font-size: 22px;
            font-weight: 600;
            line-height: 112%; } }

    &__row {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $sm) {
            flex-direction: column; }

        &-2 {
            margin-top: 12px;
            margin-bottom: 32px;

            @media (max-width: $sm) {
                flex-direction: row;
                flex-wrap: wrap;
                max-width: 300px;
                margin: 0 auto;
                margin-bottom: 16px;
                margin-top: 24px; } }

        p {
            color: $black;
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;

            @media (max-width: $sm) {
                font-size: 16px;
                font-weight: 400;
                line-height: 140%; } }

        .button-tg {
            max-width: 139px;
            height: 39px;
            background: linear-gradient(180deg, #2AABEE 0%, #229ED9 10000%);
            border-radius: 1000px;
            margin-left: 12px;

            @media (max-width: $sm) {
                margin-top: 12px;
                margin-left: 0; } }

        .button-ws {
            max-width: 149px;
            height: 39px;
            background: linear-gradient(0deg, #1FAF38 -9900%, #60D669 100%);
            border-radius: 1000px;
            margin-left: 6px;

            @media (max-width: $sm) {
                margin-top: 12px; } }

        .button-phone {
            background: linear-gradient(220.53deg, var(--myColor1) -22%, var(--myColor2) 107.02%);
            transition: --myColor1 0.8s, --myColor2 0.8s;
            max-width: 202px;
            height: 39px;
            border-radius: 1000px;
            margin-left: 12px;

            &:hover {
                --myColor1: #2244BB;
                --myColor2: #2244BB;
                opacity: 1; }

            @media (max-width: $sm) {
                margin-left: 0;
                margin-top: 12px;
                height: 36px;
                max-width: 200px;
                font-size: 18px;
                font-weight: 400;
                line-height: 150%; } } } }
