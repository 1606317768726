.animation {
    position: relative;
    opacity: 0;

    &.active {
        opacity: 1; } }

.active.fade-left {
    animation: fade-left 0.7s ease-in;

    @media (max-width: $lg) {
        animation: fade-top 0.7s ease-in; } }

.active.fade-right {
    animation: fade-right 0.7s ease-in;

    @media (max-width: $lg) {
        animation: fade-top 0.7s ease-in; } }

.active.fade-bottom {
    animation: fade-bottom 0.7s ease-in;

    @media (max-width: $lg) {
        animation: fade-top 0.7s ease-in; }

    &.duration-7 {
        animation: fade-bottom 0.7s ease-in; } }

.active.fade-top {
    animation: fade-top 0.7s ease-in; }

.active.opacity {
    animation: opacity 0.7s ease-in; }

@keyframes opacity {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }

@keyframes fade-left {
    0% {
        opacity: 0;
        transform: translateX(-20vw); }

    100% {
        opacity: 1;
        transform: translateX(0); } }

@keyframes fade-right {
    0% {
        transform: translateX(20vw);
        opacity: 0; }

    100% {
        transform: translateX(0);
        opacity: 1; } }

@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0; }

    100% {
        transform: translateY(0);
        opacity: 1; } }

@keyframes fade-top {
    0% {
        transform: translateY(-50px);
        opacity: 0; }

    100% {
        transform: translateY(0);
        opacity: 1; } }
