.dies {
    margin-top: 150px;
    position: relative;

    @media (max-width: $lg) {
        margin-top: 84px; }

    > svg {
        position: absolute;
        top: 20px;
        right: 230px;
        z-index: 1;

        @media (max-width: $lg) {
            display: none; } }

    h2 {
        margin-bottom: 48px;

        @media (max-width: $lg) {
            margin-bottom: 32px; } }

    &__block {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;

        @media (max-width: $lg) {
            gap: 16px;
            flex-direction: column; } }

    &__item {
        padding: 24px 32px 24px 32px;
        border-radius: 24px;
        background: rgb(255, 255, 255);
        width: 100%;

        @media (max-width: $lg) {
            padding: 20px; }

        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            max-width: calc( (100% / 3) - 80px );

            @media (max-width: $lg) {
                max-width: calc( 100% - 40px); } }

        &:nth-child(4), &:nth-child(5) {
            max-width: calc( (100% / 2) - 80px );

            @media (max-width: $lg) {
                max-width: calc( 100% - 40px); } }

        h3 {
            color: transparent;
            -webkit-background-clip: text !important;
            background-clip: text !important;
            background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%);
            font-size: 26px;
            font-weight: 600;
            line-height: 112%;
            margin-bottom: 20px;

            @media (max-width: $lg) {
                font-size: 22px;
                margin-bottom: 16px; } }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            color: $gray;

            @media (max-width: $lg) {
                font-size: 16px;
                line-height: 140%; } } } }
