#modal-request .modal-dialog {
    max-width: 700px;
    width: 100%;

    @media (max-width: $sm) {
        max-width: calc( 100% - 70px ); }

    > p {
        font-size: 14px;
        font-weight: 400;
        line-height: 114%;
        color: $gray;

        @media (max-width: $sm) {
            font-size: 12px; }

        a {
            color: #3055D8; } } }

.request {
    h3 {
        font-size: 36px;
        font-weight: 800;
        line-height: 112%;
        color: $black;
        margin-bottom: 32px;
        max-width: 500px;

        @media (max-width: $sm) {
            font-size: 24px;
            margin-bottom: 24px;
            max-width: 235px; } }

    &__btns {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 20px;

        @media (max-width: $sm) {
            flex-direction: column;
            align-items: flex-start;
            gap: 16px; }

        .button {
            background: linear-gradient(220.7deg, var(--myColor1) -22%, var(--myColor2) 107.02%);
            transition: --myColor1 0.8s, --myColor2 0.8s;
            max-width: 345px;
            height: 58px;
            font-size: 16px;

            &:hover {
                --myColor1: #2244BB;
                --myColor2: #2244BB;
                opacity: 1; }

            @media (max-width: $sm) {
                height: 50px;
                font-size: 14px; } }

        p {
            color: $black;

            @media (max-width: $sm) {
                font-size: 16px; }

            a {
                display: block;
                color: transparent;
                -webkit-background-clip: text !important;
                background-clip: text !important;
                background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%); } } }

    &__dop {
        margin-bottom: 32px;

        @media (max-width: $sm) {
            margin-bottom: 24px; }

        p {
            font-size: 26px;
            font-weight: 600;
            line-height: 112%;
            color: $black;
            margin-bottom: 16px;

            @media (max-width: $sm) {
                font-size: 22px; } }

        &-row {
            display: flex;
            align-items: center;

            @media (max-width: $sm) {
                flex-direction: column;
                align-items: flex-start; } } }

    &__field {
        margin-bottom: 32px;

        @media (max-width: $sm) {
            margin-bottom: 24px; }

        label {
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 114%;
            color: $black;
            display: block;

            @media (max-width: $sm) {
                margin-bottom: 10px; } }

        input {
            border-radius: 12px;
            background: rgb(255, 255, 255);
            max-width: calc( 100% - 36px );
            width: 100%;
            height: 59px;
            max-width: 398px;
            outline: none;
            border: none;
            padding: 0 18px;
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            color: $black;
            box-shadow: 0px 16px 19.3px 0px #112B851C;

            @media (max-width: $sm) {
                height: 50px;
                border-radius: 10px;
                padding: 0 16px;
                max-width: calc( 100% - 32px ); } } } }
