.hamburger {
    align-items: center;
    display: flex;
    flex-direction: column;
    outline: none;
    transition: transform .2s ease;
    justify-content: space-between;
    text-decoration: none;
    height: 12px;
    width: 18px;
    position: relative;
    z-index: 10;
    margin-right: 8px; }
