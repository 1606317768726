.blog-home {
    margin: 0 auto;
    max-width: 1340px;
    margin-top: 150px;
    margin-bottom: 20px;
    padding: 0 10px;

    @media (max-width: $lg) {
        margin-top: 84px; }

    h1 {
        margin-bottom: 24px;
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        line-height: 65px;
        margin-bottom: 42px;
        color: transparent;
        -webkit-background-clip: text !important;
        background-clip: text !important;
        background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%);

        @media (max-width: $lg) {
            margin-bottom: 20px;
            font-size: 32px;
            font-weight: 700;
            line-height: 120%; } } }

.blog-search {
    position: relative;
    margin: 0 auto;
    max-width: 425px;
    margin-bottom: 54px;
    z-index: 3;

    @media (max-width: $lg) {
        margin-bottom: 16px; }

    @media (max-width: 767px) {
        max-width: 100%; }

    input {
        border-radius: 3000px;
        background: rgb(255, 255, 255);
        height: 57px;
        width: 100%;
        padding-left: 24px;
        border: none;
        font-size: 18px;
        font-weight: 500;
        line-height: 182%;
        color: #202020;
        outline: none;
        border: 1px solid rgb(255, 255, 255);

        &::placeholder {
            color: #808080; }

        &:focus-visible {
            border: 1px solid #0D9866;

            ~svg g path {
                fill: #0D9866; } }

        @media (max-width: $lg) {
            font-size: 16px;
            font-weight: 500;
            line-height: 182%;
            height: 45px;
            padding-left: 18px; } }

    svg {
        position: absolute;
        right: 0;
        top: 12.5px;

        @media (max-width: $lg) {
            top: 6px; }

        g path {
            transition: all 0.7 ease; } }

    .search-items {
        width: 100%;
        position: absolute;
        left: 0;
        top: 67px;
        border-radius: 20px;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
        background: rgb(255, 255, 255);
        display: none;
        flex-direction: column;
        animation: opacity-in 0.7s forwards;

        @media (max-width: $lg) {
            top: 54px; }

        &.active {
            display: flex; }

        .seacrh-all {
            padding-left: 24px;
            padding-top: 14px;
            padding-bottom: 14px;
            a {
                font-size: 18px;
                font-weight: 500;
                line-height: 140%;
                color: #0D9866; } }

        .seacrh-item {
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 14px;
            padding-bottom: 14px;
            position: relative;

            a {
                font-size: 18px;
                font-weight: 500;
                line-height: 140%;
                color: #202020;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;

                p {
                    max-width: 320px; }

                svg {
                    position: static;
                    min-width: 32px;
                    min-height: 32px; } } } } }

.blog-tabs {
    display: flex;
    align-items: center;
    border-radius: 1000px;
    background: rgb(255, 255, 255);
    gap: 10px;
    padding: 8px;
    max-width: fit-content;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 72px;

    @media (max-width: $lg) {
        display: none; }

    button {
        height: 51px;
        background: initial;
        border-radius: 1000px;
        max-width: 156px;
        min-width: 156px;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
        cursor: pointer;

        &.active {
            background: rgb(230, 235, 231); } } }

.blog-tabs-mob {
    display: none;
    height: 41px;
    margin-bottom: 24px;

    @media (max-width: $lg) {
        display: block; }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;

        button {
            height: 41px;
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            background: none; } }

    .tabs__btn {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 1px solid #0D9866;
        position: absolute;
        top: 5px;
        z-index: 2;

        &.swiper-button-next {
            right: 0; }

        &.swiper-button-prev {
            left: 0; }

        &.swiper-button-disabled {
            opacity: 0.5; } } }

.blog-articles {
    display: none;
    flex-wrap: wrap;
    gap: 32px;

    &.active {
        display: flex; }

    .article {
        max-width: calc( 33% - 18px);
        border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 0px 20px 0px #DBDBDB;

        @media (max-width: $lg) {
            max-width: 100%; }

        &__img {
            img {
                max-height: 300px;
                width: 100%;
                max-width: 100%;
                object-fit: cover;
                border-radius: 30px 30px 0 0; } }

        &__block {
            padding: 18px;
            min-height: 340px;
            display: flex;
            flex-direction: column;
            justify-content: space-between; }

        &__text {
            color: black;

            &:hover {
                h3,p {
                    color: black; } }

            h3,p {
                color: $black;
                transition: all 0.6s ease; }

            h3 {
                color: $black;
                font-size: 26px;
                margin-bottom: 12px; } }

        &__data {
            margin-top: 24px;
            display: flex;
            align-items: center;
            gap: 16px;
            color: #202020;
            opacity: 0.6; }

        &__date, &__time {
            display: flex;
            align-items: center;
            gap: 6px; }

        &__tags {
            display: none;
            align-items: center;
            gap: 8px;
            opacity: 0.5;

            button,a {
                display: flex;
                align-items: center;
                justify-content: center;
                background: none;
                padding: 6px 16px 6px 16px;
                border: 1px solid rgb(82, 5, 158);
                border-radius: 300px;
                font-size: 14px;
                font-weight: 500;
                line-height: 19px;
                color: #52059E; } } } }

@keyframes opacity-in {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }
