.blocks-reverse {
    margin-top: 150px;

    @media (max-width: $lg) {
        margin-top: 84px; }

    h2 {
        margin-bottom: 48px;

        span {
            color: transparent;
            -webkit-background-clip: text !important;
            background-clip: text !important;
            background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%); } }

    &__block {
        display: flex;
        flex-direction: column;
        gap: 100px;

        @media (max-width: $lg) {
            gap: 84px; } }

    &__item {
        display: flex;
        justify-content: space-between;
        gap: 84px;

        @media (max-width: $lg) {
            flex-direction: column;
            gap: 32px; }

        &:nth-child(even) {
            flex-direction: row-reverse;

            @media (max-width: $lg) {
                flex-direction: column; } }

        img {
            @media (max-width: $lg) {
                width: 100%;
                height: auto; } }

        h3 {
            font-size: 26px;
            font-weight: 600;
            line-height: 112%;
            margin-bottom: 20px;
            color: $black;

            @media (max-width: $lg) {
                margin-bottom: 16px;
                font-size: 24px; } }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            color: $gray;
            margin-bottom: 48px;

            @media (max-width: $lg) {
                font-size: 16px;
                line-height: 140%;
                margin-bottom: 32px; } }

        .button {
            order-radius: 12px;
            box-shadow: 0px 10px 20px 0px rgb(213, 217, 239);
            background: linear-gradient(220.53deg, var(--myColor1) -14.127%,var(--myColor2) 105.65%);
            transition: --myColor1 0.8s, --myColor2 0.8s;
            max-width: 250px;
            height: 58px;
            font-size: 16px;
            font-weight: 400;
            line-height: 114%;

            &:hover {
                --myColor1: #2244BB;
                --myColor2: #2244BB;
                opacity: 1; }

            @media (max-width: $lg) {
                max-width: 100%;
                height: 50px;
                font-size: 16px; } } } }
