body {
    background: #F7F8FD;
    font-family: "Manrope";
    margin: 0;
    font-weight: 400; }

.page {
    overflow-x: hidden;
    @media (min-width: 1921px) {
        max-width: none !important; }

    &__content {
        position: relative;
        overflow: hidden; } }

.container-main {
    max-width: 1340px;
    margin: 0 auto;

    @media (max-width: $lg) {
        padding: 0 16px; } }

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 12px;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    width: 100%;
    height: 61px;
    white-space: nowrap;
    transition: all 2s;

    &:hover {
        opacity: 0.8;
        color: #FFFFFF; } }

h1,h2,h3,h4,h5,h6,p {
    padding: 0;
    margin: 0;
    line-height: 150%;
    color: #C5C5C5; }

a {
    transition: all 0.6s linear;
    text-decoration: none; }

button {
    border: none;
    padding: 0;
    background: none;

    &:hover {
        opacity: 0.8; } }

.section-title {
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    color: $black;

    @media (max-width: $lg) {
        font-size: 28px;
        line-height: 110%; } }

.modal {
    &.fade {
        display: none; }

    &.show {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex !important;
        align-items: center;
        justify-content: center;
        z-index: 11; }

    .modal-dialog {
        background: #F7F8FD;
        border-radius: 30px;
        padding: 32px;
        position: relative;

        @media (max-width: $sm) {
            padding: 20px;
            border-radius: 20px; }

        .btn-close {
            position: absolute;
            top: 32px;
            right: 32px;
            cursor: pointer;

            @media (max-width: $sm) {
                top: 20px;
                right: 20px;

                svg {
                    width: 24px;
                    height: 24px; } } } } }

.modal-backdrop {
    background: #13002C;
    opacity: 0.6;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10; }

.radio-group {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: $sm) {
        flex-direction: column;
        gap: 20px;
        align-items: baseline; }

    input[type="radio"]:checked,
    input[type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px; }

    input[type="radio"]:checked+label,
    input[type="radio"]:not(:checked)+label {
        display: inline-block;
        position: relative;
        padding-left: 32px;
        line-height: 24px;
        cursor: pointer;
        white-space: nowrap;

        @media (max-width: $sm) {
            font-size: 16px;
            line-height: 20px; } }

    input[type="radio"]:checked+label:before,
    input[type="radio"]:not(:checked)+label:before {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        width: 18px;
        height: 18px;
        border: 1px solid #D9D9D9;
        background-color: #ffffff; }

    input[type="radio"]:checked+label:before,
    input[type="radio"]:not(:checked)+label:before {
        border-radius: 100%; }

    input[type="radio"]:checked+label:after,
    input[type="radio"]:not(:checked)+label:after {
        content: "";
        position: absolute;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease; }

    input[type="radio"]:checked+label:after,
    input[type="radio"]:not(:checked)+label:after {
        left: 5px;
        top: 5px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #3055D8; }

    input[type="radio"]:checked+label:before {
        border: 1px solid #3055D8; }

    input[type="radio"]:not(:checked)+label:after {
        opacity: 0; }

    input[type="radio"]:checked+label:after {
        opacity: 1; } }

.drop {
    margin-left: 16px;

    @media (max-width: $sm) {
        margin-left: 0;
        margin-top: 16px;
        width: 100%; }

    span.msg,
    span.choose {
        color: #555;
        padding: 5px 0 10px;
        display: inherit; }

    .dropdown.block {
        opacity: 0.6;
        pointer-events: none;
        box-shadow: none;

        .select>svg path {
            fill: #222222; } }

    .dropdown {
        width: 180px;
        display: inline-block;
        background-color: #fff;
        transition: all .5s ease;
        position: relative;
        height: 59px;
        text-align: left;
        border-radius: 12px;
        color: #8B8597;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        border: 1px solid #fff;
        box-shadow: 0px 16px 19.3px 0px #112B851C;

        @media (max-width: $sm) {
            max-width: 100%;
            width: 100%;
            font-size: 16px;
            height: 48px; } }

    .dropdown .select {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 12px 16px 18px;

        @media (max-width: $sm) {
            padding: 12px;
            padding-left: 10px;
            padding-right: 16px; } }

    .dropdown .select>svg {
        transition: all .3s ease-in-out;
        float: right;
        max-width: 16px;
        max-height: 16px;
        min-width: 16px;
        min-height: 16px; }

    .dropdown.active {
        .select>svg {
            transform: rotate(180deg); } }

    .dropdown.active:hover,
    .dropdown.active {
        border: 1px solid #3055D8; }

    .dropdown .dropdown-menu {
        position: absolute;
        background-color: #fff;
        width: 100%;
        left: 0;
        margin-top: 10px;
        border-radius: 12px;
        overflow: hidden;
        display: none;
        max-height: 260px;
        overflow-y: auto;
        z-index: 9;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);

        @media (max-width: $sm) {
            max-height: 185px;
            margin-top: 6px; } }

    .dropdown .dropdown-menu li {
        padding: 10px;
        transition: all .2s ease-in-out;
        cursor: pointer;
        color: black;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        margin: 0;

        @media (max-width: $lg) {
            font-size: 14px;
            padding: 8px 8px 8px 10px; } }

    .dropdown .dropdown-menu {
        padding: 0;
        list-style: none; }

    .dropdown .dropdown-menu li:hover {
        background-color: #f2f2f2; }

    .dropdown .dropdown-menu li:active {
        background-color: #e2e2e2; } }

.error {
    min-height: calc( 100vh - 510px );
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: $lg) {
        padding: 32px 0; }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: $lg) {
            flex-direction: column;
            position: relative; }

        img {
            @media (max-width: $lg) {
                max-width: calc( 100vw - 60px );
                height: auto;
                position: absolute;
                bottom: 60px;
                left: 0;
                right: 0;
                margin: auto; } } }

    &__text {
        max-width: 450px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        h1 {
            font-size: 36px;
            line-height: 40px;
            font-weight: 800;
            color: $black;
            margin-bottom: 0;

            @media (max-width: $lg) {
                font-size: 28px;
                line-height: 30px; } }

        p {
            font-size: 20px;
            line-height: 30px;
            color: $gray;

            @media (max-width: $lg) {
                font-size: 16px;
                line-height: 22px; } }

        .button {
            max-width: 177px;
            height: 58px;
            background: #3055D8;
            color: white;
            font-size: 16px;

            @media (max-width: $lg) {
                max-width: 100%;
                height: 46px;
                font-size: 14px;
                margin-top: 200px; } } } }

.textblock {
    margin-top: 48px;

    h1 {
        font-size: 54px;
        font-weight: 800;
        line-height: 110%;
        margin-bottom: 24px;
        color: $black;

        @media (max-width: $lg) {
            font-size: 30px; } }

    h2 {
        font-size: 54px;
        font-weight: 800;
        line-height: 110%;
        margin-bottom: 24px;
        color: $black;

        @media (max-width: $lg) {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 800;
            line-height: 112%; } }

    h3 {
        font-size: 26px;
        font-weight: 600;
        line-height: 112%;
        color: $black;
        margin-bottom: 24px;

        @media (max-width: $lg) {
            margin-bottom: 20px;
            font-size: 22px;
            font-weight: 600;
            line-height: 112%; } }

    ul {
        list-style: disc;
        padding-left: 20px; }

    p, li {
        font-size: 20px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 24px;
        color: #8B8597;

        @media (max-width: $lg) {
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
            line-height: 140%; }

        a {
            color: #3055D8;
            margin-left: 6px; } }

    .block {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 24px;

        &__row {
            display: flex;
            gap: 30px;

            @media (max-width: $lg) {
                flex-direction: column;
                gap: 8px; }

            p {
                font-size: 18px;
                margin-bottom: 0;

                &:first-child {
                    max-width: 30%;
                    width: 100%;
                    font-weight: 700;

                    @media (max-width: $lg) {
                        max-width: 100%; } } } } } }

@keyframes opacity {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

@property --myColor1 {
    syntax: '<color>';
    initial-value: #CD01BA;
    inherits: false; }

@property --myColor2 {
    syntax: '<color>';
    initial-value: #015AFE;
    inherits: false; }

@property --myColor3 {
    syntax: '<color>';
    initial-value: $black;
    inherits: false; }

@property --myColor4 {
    syntax: '<color>';
    initial-value: $black;
    inherits: false; }
