.conversion {
    margin-top: 85px;

    @media (max-width: $lg) {
        margin-top: 42px; }

    > h2 {
        font-size: 36px;
        line-height: 40px;
        font-weight: 800;
        text-align: center;
        color: transparent;
        -webkit-background-clip: text !important;
        background-clip: text !important;
        background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%);

        @media (max-width: $lg) {
            margin-bottom: 24px;
            font-size: 24px;
            line-height: 112%; } }

    &__img {
        display: none;

        picture {
            width: 100%;

            img {
                width: 100%;
                height: auto; } }

        @media (max-width: $lg) {
            display: block; } }

    &__imgs {
        position: relative;
        height: 502px;
        margin-bottom: 64px;

        @media (max-width: $lg) {
            display: none; }

        picture {
            position: absolute; }

        .light {
            top: -80px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center; }

        .conversion-1 {
            top: 120px;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center; }

        .conversion-2 {
            top: 75px;
            left: 408px; }

        .conversion-3 {
            top: 143px;
            right: 359px; }

        .star-1 {
            top: 172px;
            right: 200px; }

        .star-2 {
            top: 272px;
            right: 500px; }

        .star-3 {
            top: 70px;
            right: 700px; }

        .star-4 {
            top: 175px;
            left: 195px; }

        .star-5 {
            top: 40px;
            right: 320px; }

        .star-6 {
            top: 279px;
            left: 400px; }

        .arrow {
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            justify-content: center; }

        &.active {
            .conversion-1, .conversion-2, .conversion-3, .star-1, .star-2, .star-3, .star-4, .star-5, .star-6, .arrow {
                animation: fade-zoom 0.8s ease-in; }

            @keyframes fade-zoom {
                0% {
                    transform: scale(0.2);
                    opacity: 0; }

                100% {
                    transform: scale(1);
                    opacity: 1; } } } } }

.conversion__block {
    background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%);
    border-radius: 32px;
    position: relative;
    padding: 42px 32px;

    @media (max-width: $lg) {
        margin-top: 52px;
        padding: 32px 12px 12px 12px;
        border-radius: 20px; }

    picture {
        position: absolute;

        @media (max-width: $lg) {
            position: static;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            margin-top: 24px; }

        &.block-1 {
            left: 39px;
            top: 20px; }

        &.block-2 {
            right: 23px;
            top: 100px;

            @media (max-width: $lg) {
                display: none; } } }

    h2 {
        max-width: 590px;
        width: 100%;
        font-size: 54px;
        line-height: 60px;
        font-weight: 800;
        text-align: center;
        color: white;
        margin: auto;
        margin-bottom: 30px;

        @media (max-width: $lg) {
            font-size: 28px;
            line-height: 110%;
            margin-bottom: 16px; } }

    p {
        color: white;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        max-width: 553px;
        width: 100%;
        margin: auto;
        margin-bottom: 48px;

        @media (max-width: $lg) {
            font-size: 16px;
            line-height: 140%;
            margin-bottom: 32px; } }

    button {
        background: white;
        border-radius: 16px;
        max-width: 339px;
        margin: auto;
        transition: all 0.8s;

        @media (max-width: $lg) {
            height: 62px;
            border-radius: 12px;
            font-size: 18px;
            font-weight: 400; }

        p {
            color: transparent;
            -webkit-background-clip: text !important;
            background-clip: text !important;
            background: linear-gradient(220.7deg, #CD01BA -22%, #015AFE 107.02%);
            margin: 0; }

        &:hover {
            background: initial;
            border: 1px solid white;
            p {
                color: white; } } }

    &.active {
        .conversion__text {
            animation: fade-top-1 0.8s ease-in;

            @keyframes fade-top-1 {
                0% {
                    transform: translateY(-70px);
                    opacity: 0; }

                100% {
                    transform: translateY(0);
                    opacity: 1; } } }

        .block-1 {
            animation: fade-zoom 0.8s ease-in; }

        .block-2 {
            animation: fade-zoom 0.8s ease-in; }

        @keyframes fade-zoom {
            0% {
                transform: translateY(150px) scale(0.1);
                opacity: 0; }

            100% {
                transform: translateY(0) scale(1);
                opacity: 1; } } } }
