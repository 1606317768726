.footer {
    margin-top: 120px;
    padding-bottom: 32px;

    @media (max-width: $lg) {
        margin-top: 40px;
        margin-bottom: 54px;
        padding-bottom: 0; }

    &__top {
        display: flex;
        justify-content: space-between;

        @media (max-width: $lg) {
            flex-direction: column-reverse; }

        > a {
            @media (max-width: $lg) {
                margin: 0 auto;
                margin-top: 48px; } } }

    &__bottom {
        text-align: center;
        margin-top: 42px;
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        color: $gray;

        @media (max-width: $lg) {
            margin-top: 16px;
            font-size: 14px;
            line-height: 130%; } }

    &__block {
        display: flex;
        gap: 42px;

        @media (max-width: $lg) {
            flex-direction: column;
            gap: 24px; }

        nav {
            width: 100%; }

        ul {
            width: min-content;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 32px;

            @media (max-width: $lg) {
                display: flex;
                flex-direction: column;
                gap: 24px; }

            li {
                width: fit-content;

                a {
                    white-space: nowrap;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 150%;
                    color: transparent;
                    -webkit-background-clip: text !important;
                    background-clip: text !important;
                    background: linear-gradient(220.7deg, var(--myColor3) -22%, var(--myColor4) 107.02%);
                    transition: --myColor3 0.8s, --myColor4 0.8s;

                    &:hover {
                        --myColor3: #CD01BA;
                        --myColor4: #015AFE; } } } } }

    &__right {
        width: 100%;
        max-width: 220px;

        @media (max-width: $lg) {
            max-width: 100%; }

        a {
            display: flex;
            max-width: fit-content;
            gap: 12px;
            align-items: center;
            margin-bottom: 16px;
            color: transparent;
            -webkit-background-clip: text !important;
            background-clip: text !important;
            background: linear-gradient(220.7deg, var(--myColor3) -22%, var(--myColor4) 107.02%);
            transition: --myColor3 0.8s, --myColor4 0.8s;

            &:last-child {
                margin-bottom: 0; }

            &:hover {
                --myColor3: #CD01BA;
                --myColor4: #015AFE; }

            @media (max-width: $lg) {
                margin-bottom: 24px; } }

        .button {
            border-radius: 12px;
            box-shadow: 0px 10px 20px 0px rgb(213, 217, 239);
            background: linear-gradient(220.53deg, var(--myColor1), var(--myColor2));
            transition: --myColor1 0.8s, --myColor2 0.8s;
            max-width: 220px;
            width: 100%;
            height: 46px;
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 400;
            line-height: 114%;
            color: white;

            &:hover {
                --myColor1: #2244BB;
                --myColor2: #2244BB;
                opacity: 1; }

            @media (max-width: $lg) {
                max-width: 100%;
                font-size: 14px;
                margin-bottom: 24px; } }

        &-social {
            display: flex;
            align-items: center;
            gap: 12px;

            @media (max-width: $lg) {
                gap: 16px; }

            a {
                margin-bottom: 0;
                display: inline-block; } } } }
