/* Include fonts
 @mixin ======================================================================== */
@include fontFace('Manrope', 'Manrope-Regular', 400, 'normal', 'eot');
@include fontFace('Manrope', 'Manrope-Regular', 400, 'normal', 'woff');
@include fontFace('Manrope', 'Manrope-Regular', 400, 'normal', 'woff2');

@include fontFace('Manrope', 'Manrope-Semibold', 600, 'normal', 'eot');
@include fontFace('Manrope', 'Manrope-Semibold', 600, 'normal', 'woff');
@include fontFace('Manrope', 'Manrope-Semibold', 600, 'normal', 'woff2');

@include fontFace('Manrope', 'Manrope-ExtraBold', 800, 'normal', 'eot');
@include fontFace('Manrope', 'Manrope-ExtraBold', 800, 'normal', 'woff');
@include fontFace('Manrope', 'Manrope-ExtraBold', 800, 'normal', 'woff2');
